* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sandbox {
  height: 100vh;
  overflow: hidden;
  display: flex;
  transition: width 0.5s ease-in-out;
}

.page_wrapper {
  display: flex;
}

.page_wrapper_w_95 {
  width: 95%;
  transition: width 0.5s ease-in-out;
}

.page_wrapper_w_80 {
  width: calc(100vw - 300px);
  transition: width 0.5s ease-in-out;
}

@media screen and (max-width: 1040px) {
  .sandbox{
    min-height:100vh;
  }
}

@media screen and (max-width:768px)
{
  .page_wrapper_w_95{
    width: 100%;
    margin-left: 10%;
  }
  .page_wrapper_w_80{
    width: 100%;
    transition: width 0.5s ease-in-out;
  }
  .sandbox{
    min-height: 100vh;
    overflow: visible;
  }
}


@media screen and (max-width: 480px) {
  .page_wrapper_w_95 {
    width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    height: max-content;
    padding-bottom: 30px;
    height: 100%;
    margin-left: 0;
  }
  .page_wrapper_w_80 {
    width: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  .sandbox {
    min-height: 100vh;
    overflow: visible;
  }
}