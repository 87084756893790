.loading-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.loading-button-message{
    color: white;
}
.loading-button-progress svg circle{
    color: white;
}