.preloaderBg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}

.preloader {
  background: url("../../Assets/logo_truxt.png") no-repeat center;
  background-size: 150px;
  width: 300px;
  height: 300px;
}

.preloader2 {
  border: 5px solid #f2f2f2;
  border-top: 5px solid #003f75;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  animation: spin 1s ease-in-out infinite;
  position: absolute; /* Position this relative to its container */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.response-card-loader{
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 10px;
}
