:root {
  --blue-color: #064277;
  --sky-blue-color: #a7faff;
  --background-color: rgba(194, 207, 218, 0.85);
  --font-family: "Poppins", sans-serif;
  --input-border-color: black;
  --input-border-bottom-width: 0.5px;
  --input-padding: 10px;
  --label-font-size: 0.9em;
  --label-font-weight: bold;
  --label-margin-bottom: 2px;
  --button-padding: 10px;
  --button-border-radius: 8px;
  --button-color: white;
  --button-background-color: var(--blue-color);
  --h1-border-bottom-color: var(--blue-color);
  --border-bottom-sky-blue-color: var(--sky-blue-color);
  --h1-color: var(--blue-color);
  --h1-padding-bottom: 5px;
  --h1-font-size: 2.3rem;
  --a-color: var(--blue-color);
  --a-font-size: 1rem;
  --a-margin-top: 2px;
}

.Login {
  height: 100vh;
  background: url("../../Assets/truxt_login_background.jpg") center center / cover no-repeat;
  display: flex;
  justify-content: space-between;
}

.login_content_wrapper {
  width: 70%;
}

.login_content_wrapper img {
  width: 50%;
}

.content_container {
  font-family: var(--font-family);
  line-height: 1.2;
  width: 100%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
  color: #a7faff;
  div {
    transform: translateX(-20px);
    font-family: var(--font-family);
  }
  hr {
    margin: 0 0 0 50px !important;
    padding-top: 5px;
    border: 0;
    border-top: 2px solid;
    color: #a7faff;
  }
  p {
    color: #a7faff;
    margin-bottom: 0;
    font-family: var(--font-family);
    font-family: var(--font-family);
  }
  span {
    color: #a7faff;
    margin-right: 8px;
    font-family: var(--font-family);
  }
}

.content_container_second {
  width: fit-content;
  margin-left: 70px;
  font-family: var(--font-family);
  font-family: var(--font-family);
}

.bold-headline {
  font-weight: bolder;
  font-family: var(--font-family);
}

.content_container img {
  width: 100%;
  max-width: 150px;
  height: auto;
}

.login_wrapper {
  height: 100%;
  width: 30%;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-wrapper {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
        /* border: 2px solid black; */
        width: 30%;
    }
    h2{
        font-size: 1.2rem;
        text-align: center;
        width: 80%;
        color: white;
    }

}

.login_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.login_container h1 {
  border-bottom: 1px solid var(--h1-border-bottom-color);
  color: var(--h1-color);
  padding-bottom: var(--h1-padding-bottom);
  font-size: var(--h1-font-size);
  font-family: var(--font-family);
}

.login_container button {
  padding: var(--button-padding);
  cursor: pointer;
  border: none;
  border-radius: var(--button-border-radius);
  background-color: var(--button-background-color);
  color: var(--button-color);
  font-family: var(--font-family);
}

.input_section label {
  display: block;
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
  margin-bottom: var(--label-margin-bottom);
  font-family: var(--font-family);
}

.input_section input {
  width: 100%;
  padding: var(--input-padding);
  outline: none;
  border: none;
  border-bottom: var(--input-border-bottom-width) solid var(--input-border-color);
  font-family: var(--font-family);
  font-size: 0.7rem;
}

.input_section a {
  color: var(--a-color);
  font-size: var(--a-font-size);
  float: right;
  font-family: var(--font-family);
  margin-top: var(--a-margin-top);
  text-decoration: none;
  margin-top: 5px;
}

.input_section a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .login_content_wrapper {
    display: none;
  }

  .login_wrapper {
    width: 70%;
    margin: auto;
    height: max-content;
    padding: 50px 10px;
    border-radius: 6px;
  }

  :root {
    --label-font-size: 0.8em;
    --input-padding: 8px;
    --button-padding: 8px;
    --label-margin-bottom: 8px;
  }
}

@media screen and (max-width: 480px) {
  .login_wrapper {
    width: 80%;
  }
}
